<template>
  <DeviceBulkCommandList />
</template>

<script>
export default {
  name: "DeviceBulkCommandListView",
  components: {
    DeviceBulkCommandList: () => import('@/components/Device/DeviceBulkCommandList.vue')
  },
  metaInfo () {
    return {
      title: this.$t('menu.bulkCommand')
    }
  }
}
</script>
